import { customElement, extend, window, listen } from '@chialab/dna';

/**
 * A form upload with a single button.
 */
@customElement('cms-button-upload', {
    extends: 'form',
})
export class ButtonUpload extends extend(window.HTMLFormElement) {
    /**
     * @inheritdoc
     */
    render() {
        return <>
            <slot name="input"></slot>
            <slot></slot>
        </>;
    }

    /**
     * Reset input changes on click.
     * @param event The change event.
     */
    @listen('click', 'input[type="file"]')
    private onClick(event: Event, input: HTMLInputElement) {
        input.value = '';
    }

    /**
     * Handle file input changes.
     * @param event The change event.
     * @param input The file input.
     */
    @listen('change', 'input[type="file"]', { capture: true })
    private onInputChanged(event: Event) {
        event.stopPropagation();
        this.submit();
    }
}

declare module '@chialab/dna' {
    namespace JSX {
        interface CustomElements {
            'cms-button-upload': ButtonUpload & {
                extends: 'form';
            };
        }
    }
}
