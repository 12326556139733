import type { Menu } from '@chialab/dna-menu';
import { delegateEventListener, document } from '@chialab/dna';
import './theme';
import './locale';
import '@chialab/dna-button';
import '@chialab/dna-date-time';
import '@chialab/dna-sidenav';
import '@chialab/dna-spinner';
import '@chialab/dna-tabs';
import '@chialab/dna-menu';
import './register';
import './Elements/ButtonUpload/ButtonUpload';
import './Elements/ContentPoster/ContentPoster';
import './Elements/Blocks/Tab';
import { client } from './Lib/ApiClient';

client.setCsrfToken(document.querySelector('meta[name="csrfToken"]')?.getAttribute('content') ?? '');

delegateEventListener(document.documentElement, 'click', 'button[rel]', (event, button) => {
    event.preventDefault();

    const rel = (button as HTMLElement).getAttribute('rel');
    const menu = document.querySelector(`dna-menu[id="${rel}"]`) as Menu;
    if (menu) {
        const isOpen = menu.open;
        setTimeout(() => {
            if (!isOpen) {
                menu.show();
            }
        });
    }
});
